import {SourceType} from '@matchsource/models/source';

export class LoadSearches {
  static readonly type = '[Searches] Load';
}

export class LoadRunningSearches {
  static readonly type = '[Searches] Load Running';
}

export class LoadUnviewedSearches {
  static readonly type = '[Searches] Load Unviewed';
}

export class MarkAsViewedSearches {
  static readonly type = '[Searches] View Searches';

  constructor(public searchGuids: MsApp.Guid[]) {}
}

export class MarkAsViewedSourceSearch {
  static readonly type = '[Searches] View Source Search';

  constructor(
    public searchGuids: MsApp.Guid[],
    public sourceType: SourceType
  ) {}
}
