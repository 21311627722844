import {Action, Selector, State, StateContext} from '@ngxs/store';
import {catchError, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {compose} from '@ngxs/store/operators';
import {
  addOrReplaceEntities,
  loadingEntities,
  EntitiesStateModel,
  loadedEntities,
  defaultEntitiesState,
  setError,
} from '@matchsource/store/core';
import {of, zip} from 'rxjs';
import {
  LoadRunningSearches,
  LoadSearches,
  LoadUnviewedSearches,
  MarkAsViewedSearches,
  MarkAsViewedSourceSearch,
} from './searches.action';
import {SearchApiService} from '@matchsource/api/search';
import {SearchModel, SearchStatus} from '@matchsource/models/search';
import {SearchControllerService} from '@matchsource/api-generated/search-match';
import difference from 'lodash-es/difference';

export type SearchesStateModel = EntitiesStateModel<SearchModel>;

@State<SearchesStateModel>({
  name: 'searches',
  defaults: defaultEntitiesState<SearchModel>(),
})
@Injectable()
export class SearchesState {
  constructor(
    private readonly searchApi: SearchApiService,
    private readonly searchController: SearchControllerService
  ) {}

  @Selector([SearchesState])
  static inProgressOrLoaded(state: SearchesStateModel) {
    return state.loaded || state.loading;
  }

  @Selector([SearchesState])
  static searchesMap(state: SearchesStateModel) {
    return state.entities;
  }

  @Action(LoadSearches)
  loadAll(ctx: StateContext<SearchesStateModel>) {
    return this.load(ctx, {
      status: [
        SearchStatus.Running,
        SearchStatus.Completed,
        SearchStatus.Failed,
        SearchStatus.Pending,
        SearchStatus.InQueue,
        SearchStatus.Deferred,
      ],
      viewedInd: false,
    });
  }

  @Action(LoadRunningSearches)
  loadRunning(ctx: StateContext<SearchesStateModel>) {
    const params = {
      status: [SearchStatus.Deferred, SearchStatus.Pending, SearchStatus.InQueue, SearchStatus.Running],
      viewedInd: false,
    };

    ctx.setState(loadingEntities(true));

    return this.searchApi.retrieveSearches(params, true).pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(searches => {
        const prevSearches = ctx.getState().entities;
        const prevSearchIds = Object.keys(prevSearches);
        const searchIds = searches.map(search => search.searchGuid);
        const becameCompletedIds = difference(prevSearchIds, searchIds);

        const completedSearches = becameCompletedIds.map(id => ({
          ...prevSearches[id],
          sourceSearches: prevSearches[id].sourceSearches.map(sourceSearch => ({
            ...sourceSearch,
            status: SearchStatus.Completed,
          })),
        }));

        ctx.setState(
          compose(
            addOrReplaceEntities<SearchModel>('searchGuid', [...searches, ...completedSearches]),
            loadedEntities(true),
            loadingEntities(false)
          )
        );
      })
    );
  }

  @Action(LoadUnviewedSearches)
  loadUnviewed(ctx: StateContext<SearchesStateModel>) {
    return this.load(
      ctx,
      {
        status: [SearchStatus.Completed, SearchStatus.Failed],
        viewedInd: false,
      },
      true
    );
  }

  @Action(MarkAsViewedSearches)
  view(ctx: StateContext<SearchesStateModel>, {searchGuids}: MarkAsViewedSearches) {
    return this.searchController.clearSearches1({body: searchGuids}).pipe(
      catchError(error => {
        ctx.setState(setError(error));
        return of(null);
      }),
      tap(() => {
        const searches = ctx.getState().entities;

        const viewedSearches = searchGuids.map(id => {
          return {
            ...searches[id],
            sourceSearches: searches[id].sourceSearches.map(sourceSearch => ({
              ...sourceSearch,
              viewed: true,
            })),
          };
        });

        ctx.setState(addOrReplaceEntities<SearchModel>('searchGuid', viewedSearches));
      })
    );
  }

  @Action(MarkAsViewedSourceSearch)
  viewedSourceSearch(ctx: StateContext<SearchesStateModel>, {searchGuids, sourceType}: MarkAsViewedSourceSearch) {
    return zip(...searchGuids.map(searchGuid => this.searchController.clearSearches({searchGuid, sourceType}))).pipe(
      catchError(error => {
        ctx.setState(setError(error));
        return of(null);
      }),
      tap(() => {
        const searches = ctx.getState().entities;

        const viewedSearches = searchGuids.map(id => ({
          ...searches[id],
          sourceSearches: searches[id].sourceSearches.map(sourceSearch => ({
            ...sourceSearch,
            viewed: sourceSearch.sourceType === sourceType ? true : sourceSearch.viewed,
          })),
        }));

        ctx.setState(addOrReplaceEntities<SearchModel>('searchGuid', viewedSearches));
      })
    );
  }

  private load(
    ctx: StateContext<SearchesStateModel>,
    params: {
      status: Array<SearchStatus>;
      viewedInd?: boolean;
      recipientGuid?: string;
    },
    silent = false
  ) {
    ctx.setState(loadingEntities(true));

    return this.searchApi.retrieveSearches(params, silent).pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(searches => {
        ctx.setState(
          compose(
            addOrReplaceEntities<SearchModel>('searchGuid', [...searches]),
            loadedEntities(true),
            loadingEntities(false)
          )
        );
      })
    );
  }
}
